import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";

import App from "./App";

const mapStateToProps = (state, props) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

// "withRouter()" is neccessary for the container component of the root component.
// Otherwise routes will not function at all.
const AppContainer: any = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(App)
);

export default AppContainer;
