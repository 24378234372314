import * as React from "react";
import "./LabelledContentItem.scss";

export default class LabelledContentItem extends React.Component<any, any> {
	render() {
		return (
			<li
				className={
					"labelled-content-item" +
					(this.props.emphasis ? " labelled-content-item--emphasis" : "")
				}
			>
				<div className="labelled-content-item__label labelled-content-item__label--dark">
					{this.props.title}
				</div>
				<div className="labelled-content-item__content labelled-content-item__content--dark">
					{this.props.items &&
						this.props.items.map((item, index) => {
							if (this.props.no_background) {
								return <div key={index}>{item}</div>;
							} else {
								return (
									<div key={index} className="text-container">
										{item}
									</div>
								);
							}
						})}
				</div>
			</li>
		);
	}
}
