import { fetchSocialMediaPosts } from "../../store/social_media_posts/ajax/fetchSocialMediaPosts";

export default {
	mapStateToProps: (store_state, props) => {
		return {
			social_media_posts: store_state.social_media_posts,
		};
	},

	mapDispatchToProps: (dispatch, props) => {
		return {
			fetchSocialMediaPosts: () => fetchSocialMediaPosts(dispatch),
		};
	},
};
