import * as React from "react";
import { Link } from "react-router-dom";

import LabelledContentItem from "../../components/LabelledContentItem/LabelledContentItem";

import text from "../../locales/en-us";

import "./Press.scss";

let logo_image = require("./images/tasow-logo_small.png");
let logo_zip = require("./images/TASOW_press-logos.zip");

export default class Press extends React.Component<any, any> {
	render() {
		let logo_content = (
			<div>
				<img src={logo_image} height="100" />
				<a href={logo_zip} target="_blank">
					{text.press.logo.download}
				</a>
			</div>
		);

		let leadership_content = text.press.leadership.items.map((item, index) => {
			return (
				<div key={index}>
					<h3>{item.name}</h3>
					<h4>{item.position}</h4>
				</div>
			);
		});

		return (
			<section className="press-page">
				<div className="restricted-container">
					<LabelledContentItem
						title="Our Mission:"
						items={[text.mission.content.mission_statement]}
						emphasis={true}
						no_background={true}
					/>

					<section className="press-content">
						<ul className="labelled-content-list">
							<LabelledContentItem
								title={text.press.logo.title}
								items={[logo_content]}
							/>
							<LabelledContentItem
								title={text.press.awards.title}
								items={text.press.awards.items}
							/>
							<LabelledContentItem
								title={text.press.leadership.title}
								items={leadership_content}
							/>
						</ul>
					</section>
				</div>
			</section>
		);
	}
}
