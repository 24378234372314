import * as React from "react";
import { Link } from "react-router-dom";
import "./LinkListBox.scss";

export default function LinkListBox({ title, links = [] }) {
	return (
		<section className="link-list-box">
			<div className="link-list-box__title">{title}</div>
			<ul className="link-list-box__list">
				{links.map(({ text, route }, index) => {
					return (
						<li key={index}>
							<Link className="link-list-box__link" to={route}>
								{text}
							</Link>
						</li>
					);
				})}
			</ul>
		</section>
	);
}
