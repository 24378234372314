import * as React from "react";
import "./SocialMediaTile.scss";

interface props {
	data: any;
}

export default class SocialMediaTile extends React.Component<props, any> {
	render() {
		let data = this.props.data;

		return (
			<a
				href={data.permalink}
				target="_blank"
				className="social-media-tile social-media-tile--facebook last-column"
			>
				<h3 className="post-title">
					<img src={data.from.picture.data.url} className="user-image" alt="" />
					<div className="icon facebook-logo"></div>
					<div className="post-info">
						<div className="post-user">{data.from.name}</div>
						<div className="post-time">{data.created_time}</div>
					</div>
				</h3>
				<div className="post-content">
					{data.full_picture && <img src={data.full_picture} alt="" />}
					<div>{data.message}</div>
				</div>
				<button className="button button--info button--no-hover">
					View on Facebook
				</button>
			</a>
		);
	}
}
