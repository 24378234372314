import axios from "axios";
import { RECEIVE_SOCIAL_MEDIA_POSTS } from "../constants";
import { SocialMediaPost } from "../models/SocialMediaPost";

export function receiveSocialMediaPosts(social_media_posts: SocialMediaPost[]) {
	return {
		type: RECEIVE_SOCIAL_MEDIA_POSTS,
		payload: {
			social_media_posts: social_media_posts,
		},
	};
}
