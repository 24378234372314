import * as React from "react";
import RadioButton from "../RadioButton/RadioButton";

import "./ToggleSwitch.scss";

export default class ToggleSwitch extends React.Component<any, any> {
	render() {
		return (
			<div className="toggle-switch">
				<RadioButton
					input_name={this.props.input_name}
					input_value={this.props.input1.value}
					label_text={this.props.input1.text}
					onChange={this.props.onChange}
					checked={this.props.input1.checked}
				/>
				<RadioButton
					input_name={this.props.input_name}
					input_value={this.props.input2.value}
					label_text={this.props.input2.text}
					onChange={this.props.onChange}
					checked={this.props.input2.checked}
				/>
			</div>
		);
	}
}
