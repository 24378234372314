import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import ContentTile from "../../components/ContentTile/ContentTile";
import LinkListBox from "../../components/LinkListBox/LinkListBox";

import text from "../../locales/en-us";

import "./Chapters.scss";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

export default class Chapters extends React.Component<any, any> {
	render() {
		return (
			<section className="chapters-page">
				<Banner
					images={banner_images}
					text={text.chapters.banner.main}
					secondary_text={text.chapters.banner.secondary}
				/>

				<div className="restricted-container restricted-container--padded">
					<section>
						<div className="block-list row-when-large">
							{text.chapters.content_tiles.map((tile, index) => {
								return <ContentTile key={index} {...tile} />;
							})}
						</div>
					</section>
				</div>
			</section>
		);
	}
}
