import * as React from "react";
import { Link } from "react-router-dom";
import "./Nav.scss";
import Button from "../Button/Button";

import text from "../../locales/en-us";
const logo = require("./images/TASOW.png");

export default class Nav extends React.Component {
	render() {
		return (
			<header className="main-header">
				<input
					id="show_menu_checkbox"
					type="checkbox"
					className="hamburger_checkbox"
				/>
				<div className="restricted-container hamburger-container">
					<div className="header-top">
						<Link to="/">
							<div className="logo">
								<img src={logo} className="logo__img" alt="TASOW" />
								<div className="logo__text">TASOW</div>
							</div>
						</Link>
						<label
							htmlFor="show_menu_checkbox"
							className="hamburger-menu-button"
						>
							<span></span>
						</label>
					</div>
					<nav className="header-links">
						<div className="link-row">
							<Link className="header-links__link" to="/education">
								{text.nav.education}
							</Link>
							<Link className="header-links__link" to="/rescue">
								{text.nav.rescue}
							</Link>
							<Link className="header-links__link" to="/volunteer">
								{text.nav.volunteer}
							</Link>
							<Link
								className="header-links__link header-links__link--subtle"
								to="/mission"
							>
								{text.nav.mission}
							</Link>
							<Link
								className="header-links__link header-links__link--subtle"
								to="/chapters"
							>
								{text.nav.locations}
							</Link>
							<Link
								className="header-links__link header-links__link--subtle last-column"
								to="/contact-us"
							>
								{text.nav.contact}
							</Link>
						</div>
					</nav>
				</div>
				<div className="donate-container restricted-container">
					<div className="restricted-container">
						<Button
							to="/donate"
							modifiers={["emphasis"]}
							className="donate-button"
						>
							{text.nav.donate}
						</Button>
					</div>
				</div>
			</header>
		);
	}
}
