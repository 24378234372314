import * as React from "react";
import { Link } from "react-router-dom";
import "./ContentTile.scss";
import Button from "../Button/Button";
import HeadingBox from "../HeadingBox/HeadingBox";
import DividedList from "../DividedList/DividedList";

export default class ContentTile extends React.Component<any, any> {
	render() {
		let { style } = this.props;
		let tile_content = (
			<div className={this.props.full_width ? "full-width" : undefined}>
				{this.props.image && (
					<div className="content-tile__image">
						<img src={this.props.image} alt="" />
					</div>
				)}
				<div
					className={
						"content-tile__text " +
						(!this.props.image ? "content-tile__text--full-width" : "")
					}
				>
					<HeadingBox>{this.props.title}</HeadingBox>
					{this.props.paragraphs &&
						this.props.paragraphs.map((paragraph, index) => {
							return <p key={index}>{paragraph}</p>;
						})}
					{this.props.items && <DividedList items={this.props.items} />}
					{this.props.action_button && (
						<Button to={this.props.to} modifiers={["info"]}>
							{this.props.action_button}
						</Button>
					)}
				</div>
			</div>
		);

		return (
			<div className="content-tile" style={style}>
				{tile_content}
			</div>
		);
	}
}
