import axios from "axios";
import { EducationalProgramInterface } from "../../../../schemas/EducationalProgramSchema";

export function postEducationalProgramForm(
	form_data: EducationalProgramInterface
) {
	return axios({
		method: "post",
		url: "/api/bookAProgram",
		data: form_data,
	});
}
