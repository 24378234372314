// Main pages
import AppContainer from "./AppContainer";
import Home, { HomeRedux } from "./pages/Home/Home";
import Education from "./pages/Education/Education";
import Rescue from "./pages/Rescue/Rescue";
import Volunteer from "./pages/Volunteer/Volunteer";
import Mission from "./pages/Mission/Mission";
import Chapters from "./pages/Chapters/Chapters";
import WesternPennsylvania from "./pages/WesternPennsylvania/WesternPennsylvania";
import EasternTennessee from "./pages/EasternTennessee/EasternTennessee";
import Pittsburgh from "./pages/Pittsburgh/Pittsburgh";
import Chattanooga from "./pages/Chattanooga/Chattanooga";
import SlipperyRock from "./pages/SlipperyRock/SlipperyRock";
import Contact from "./pages/Contact/Contact";
import Donate from "./pages/Donate/Donate";
import DonationComplete from "./pages/DonationComplete/DonationComplete";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import Press from "./pages/Press/Press";
import NotFound from "./pages/NotFound/NotFound";

// Landing pages
import VolunteerReptileRescue from "./landing_pages/VolunteerReptileRescue/VolunteerReptileRescue";

import asPage from "./decorators/asPage/asPage";
import withRedux from "./decorators/withRedux";

import text from "./locales/en-us";

const routes = [
	{
		component: AppContainer,
		routes: [
			// Main pages
			{
				path: "/",
				exact: true,
				component: asPage(withRedux(Home, HomeRedux)),
				meta: {
					title: text.page_titles.home,
					description: text.current_page_description,
				},
			},
			{
				path: "/home",
				component: asPage(withRedux(Home, HomeRedux)),
				meta: {
					title: text.page_titles.home,
					description: text.current_page_description,
				},
			},
			{
				path: "/education",
				component: asPage(Education),
				meta: {
					title: text.page_titles.education,
					description: text.current_page_description,
				},
			},
			{
				path: "/rescue",
				component: asPage(Rescue),
				meta: {
					title: text.page_titles.rescue,
					description: text.current_page_description,
				},
			},
			{
				path: "/volunteer",
				component: asPage(Volunteer),
				meta: {
					title: text.page_titles.volunteer,
					description: text.current_page_description,
				},
			},
			{
				path: "/mission",
				component: asPage(Mission),
				meta: {
					title: text.page_titles.mission,
					description: text.current_page_description,
				},
			},
			{
				path: "/chapters",
				exact: true,
				component: asPage(Chapters),
				meta: {
					title: text.page_titles.chapters,
					description: text.current_page_description,
				},
			},
			{
				path: "/chapters/western-pennsylvania",
				component: asPage(WesternPennsylvania),
				meta: {
					title: text.page_titles.western_pennsylvania,
					description: text.current_page_description,
				},
			},
			{
				path: "/chapters/eastern-tennessee",
				component: asPage(EasternTennessee),
				meta: {
					title: text.page_titles.eastern_tennessee,
					description: text.current_page_description,
				},
			},
			{
				path: "/chapters/pittsburgh-pennsylvania",
				component: asPage(Pittsburgh),
				meta: {
					title: text.page_titles.pittsburgh,
					description: text.current_page_description,
				},
			},
			{
				path: "/chapters/chattanooga-tennessee",
				component: asPage(Chattanooga),
				meta: {
					title: text.page_titles.chattanooga,
					description: text.current_page_description,
				},
			},
			{
				path: "/chapters/slippery-rock-pennsylvania",
				component: asPage(SlipperyRock),
				meta: {
					title: text.page_titles.slippery_rock,
					description: text.current_page_description,
				},
			},
			{
				path: "/contact-us",
				component: asPage(Contact),
				meta: {
					title: text.page_titles.contact,
					description: text.current_page_description,
				},
			},
			{
				path: "/donate",
				component: asPage(Donate),
				meta: {
					title: text.page_titles.donate,
					description: text.current_page_description,
				},
			},
			{
				path: "/donation-complete",
				component: asPage(DonationComplete),
				meta: {
					title: text.page_titles.donation_complete,
					description: text.current_page_description,
				},
			},
			{
				path: "/terms",
				component: asPage(Terms),
				meta: {
					title: text.page_titles.terms,
					description: text.current_page_description,
				},
			},
			{
				path: "/privacy",
				component: asPage(Privacy),
				meta: {
					title: text.page_titles.privacy,
					description: text.current_page_description,
				},
			},
			{
				path: "/press",
				component: asPage(Press),
				meta: {
					title: text.page_titles.press,
					description: text.current_page_description,
				},
			},

			// Landing pages
			{
				path: "/volunteer-reptile-rescue",
				component: asPage(VolunteerReptileRescue),
				meta: {
					title: text.landing_pages.VolunteerReptileRescue.meta.title,
					description:
						text.landing_pages.VolunteerReptileRescue.meta.description,
				},
			},

			// 404
			{
				path: "*",
				component: asPage(NotFound),
				meta: {
					title: "Page not found.",
					description: text.current_page_description,
				},
			},
		],
	},
];

export default routes;
