import * as React from "react";
import { Link, Route } from "react-router-dom";
import text from "../../locales/en-us";

import "./NotFound.scss";

export default function NotFound() {
	return (
		<Route
			render={({ staticContext }) => {
				let content: any = staticContext;
				if (content) {
					content.status = 404;
				}
				return (
					<section className="notfound-page">
						<div className="restricted-container">
							<h2>404</h2>
							<h3>Page not found</h3>

							<div>
								Since you're here, maybe you'd like to hear about what TASOW
								does:
							</div>

							<section className="labelled-content-item labelled-content-item--emphasis">
								<div className="labelled-content-item__label labelled-content-item__label--dark">
									Our Mission:
								</div>
								<div className="labelled-content-item__content labelled-content-item__content--dark">
									{text.mission.content.mission_statement}
								</div>
							</section>
							<div>
								<a href="/mission" className="button button--info">
									{text.home.banner.action_button}
								</a>
							</div>
						</div>
					</section>
				);
			}}
		/>
	);
}
