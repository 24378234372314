import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import ContentTile from "../../components/ContentTile/ContentTile";

import text from "../../locales/en-us";

import "./Chattanooga.scss";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

let tile_images = [
	require("./images/volunteers.jpg"),
	require("./images/educational_programs.jpg"),
];

export default class Chattanooga extends React.Component<any, any> {
	render() {
		return (
			<section className="chattanooga-page">
				<Banner
					images={banner_images}
					text={text.chattanooga.banner.main}
					secondary_text={text.chattanooga.banner.secondary}
				/>

				<div className="restricted-container restricted-container--padded">
					<section>
						<div className="block-list row-when-large">
							{text.chattanooga.content_tiles.map((tile, index) => {
								return (
									<ContentTile
										key={index}
										image={tile_images[index]}
										{...tile}
									/>
								);
							})}
						</div>
					</section>
				</div>
			</section>
		);
	}
}
