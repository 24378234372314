import * as Joi from "joi";

export const VolunteerFields = {
	chapter_location: {
		label: "TASOW Chapter Location",
		placeholder: "Chapter Location",
		type: "radio",
		options: [
			{
				label: "Western Pennsylvania",
				description: "The Greater Pittsburgh Area",
				value: "PA",
			},
			{
				label: "Eastern Tennessee",
				description: "The Greater Chattanooga Area",
				value: "TN",
			},
		],
		required: true,
	},
	firstname: {
		label: "First Name",
		placeholder: "Steve",
		type: "text",
		required: true,
		validation: "",
	},
	lastname: {
		label: "Last Name",
		placeholder: "Irwin",
		type: "text",
		required: true,
		validation: "",
	},
	email: {
		label: "Email",
		placeholder: "steve@example.com",
		type: "email",
		required: true,
	},
	phone: {
		label: "Phone Number",
		placeholder: "(123) 123-1234",
		type: "tel",
		required: true,
	},
	date_of_birth: {
		label: "Date of Birth",
		placeholder: "",
		type: "date",
		required: true,
	},
	full_address: {
		label: "Your Full Address",
		placeholder: "Street, city, state, etc.",
		type: "textarea",
		required: true,
	},
	why_volunteer: {
		label: "Why do you want to volunteer?",
		placeholder: "There are no wrong answers. :-)",
		type: "textarea",
		required: true,
	},
};

export interface VolunteerInterface {
	chapter_location: any;
	firstname: any;
	lastname: any;
	email: any;
	phone: any;
	date_of_birth: any;
	full_address: any;
	why_volunteer: any;
}

export const VolunteerProperties: VolunteerInterface = {
	chapter_location: Joi.string().required(),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	email: Joi.string().required(),
	phone: Joi.string().required(),
	date_of_birth: Joi.string().required(),
	full_address: Joi.string().required(),
	why_volunteer: Joi.string().required(),
};

export const VolunteerSchema = Joi.object().keys(VolunteerProperties);
