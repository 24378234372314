import * as Joi from "joi";

export const EducationalProgramFields = {
	chapter_location: {
		label: "TASOW Chapter Location",
		placeholder: "Chapter Location",
		type: "radio",
		options: [
			{
				label: "Western Pennsylvania",
				description: "The Greater Pittsburgh Area",
				value: "PA",
			},
			{
				label: "Eastern Tennessee",
				description: "The Greater Chattanooga Area",
				value: "TN",
			},
		],
		required: true,
	},
	firstname: {
		label: "First Name",
		placeholder: "Steve",
		type: "text",
		required: true,
		validation: "",
	},
	lastname: {
		label: "Last Name",
		placeholder: "Irwin",
		type: "text",
		required: true,
		validation: "",
	},
	email: {
		label: "Email",
		placeholder: "steve@example.com",
		type: "email",
		required: true,
	},
	phone: {
		label: "Phone Number",
		placeholder: "(123) 123-1234",
		type: "tel",
		required: true,
	},
	program_date: {
		label: "Desired Date",
		placeholder: "",
		type: "date",
		required: true,
	},
	start_time: {
		label: "Start Time",
		placeholder: "",
		type: "time",
		required: true,
	},
	end_time: {
		label: "End Time",
		placeholder: "",
		type: "time",
		required: true,
	},
	full_address: {
		label: "Full Address where program will be held",
		placeholder: "Street, city, state, etc.",
		type: "textarea",
		required: true,
	},
	additional_information: {
		label: "Additional Info",
		placeholder: "Anything else we need to know?",
		type: "textarea",
		required: false,
	},
};

export interface EducationalProgramInterface {
	chapter_location: any;
	firstname: any;
	lastname: any;
	email: any;
	phone: any;
	program_date: any;
	start_time: any;
	end_time: any;
	full_address: any;
	additional_information: any;
}

export const EducationalProgramProperties: EducationalProgramInterface = {
	chapter_location: Joi.string().required(),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	email: Joi.string().required(),
	phone: Joi.string().required(),
	program_date: Joi.string().required(),
	start_time: Joi.string().required(),
	end_time: Joi.string().required(),
	full_address: Joi.string().required(),
	additional_information: Joi.string().allow(""),
};

export const EducationalProgramSchema = Joi.object().keys(
	EducationalProgramProperties
);
