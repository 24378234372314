import { createStore } from "redux";

import { socialMediaPostReducer } from "./social_media_posts/reducers/socialMediaPostReducer";

function AppReducer(state: any = {}, action: any) {
	return {
		social_media_posts: socialMediaPostReducer(
			state.social_media_posts,
			action
		),
	};
}

var store = createStore(AppReducer);

export default store;
