// Google Analytics global variable
declare var ga: any;

import * as React from "react";
import { Link } from "react-router-dom";
import * as serializeForm from "form-serialize";
import update from "immutability-helper";
import * as Joi from "joi";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { styled, makeStyles } from "@material-ui/styles";

import "./Form.scss";

const useStyles = makeStyles((theme) => ({
	label: {
		backgroundColor: "#FFF",
		color: "#000",
		margin: "0 0 5px 0",
		padding: "5px 0",
	},
	label_text: {
		lineHeight: "16px",
	},
	label_description: {
		color: "#666",
		fontSize: 12,
	},
}));

function RadioField({ label, description, value, is_submitting }) {
	const classes = useStyles({});
	return (
		<FormControlLabel
			className={classes.label}
			label={
				<div>
					<div className={classes.label_text}>{label}</div>
					<div className={classes.label_description}>{description}</div>
				</div>
			}
			value={value}
			control={<Radio />}
			disabled={is_submitting}
		/>
	);
}

const StyledRadioField = styled(RadioField)({
	background: "#FFF",
});

class Form extends React.Component<any, any> {
	private validatorTypes;

	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			error_message: null,
			is_submitting: false,
			successfully_submitted: false,
		};

		// Prevents react from freaking out about switching between "controlled"
		// and "uncontrolled" inputs.
		Object.keys(this.props.fields).forEach((field_id) => {
			this.state.fields[field_id] = "";
		});

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// Any time a field changes the state is updated to match
	private handleInputChange(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		var newState = update(this.state, {
			fields: {
				[name]: { $set: value },
			},
		});
		this.setState(newState);
	}

	// When form is submitted the data is serialized and sent to the server
	private handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();

		this.setState({
			error_message: "Submitting form...",
			is_submitting: true,
		});

		let form_data = serializeForm(event.target, { hash: true });
		Joi.validate(
			this.state.fields,
			this.props.validatorTypes,
			(error, value) => {
				if (error) {
					console.error(error);
					this.setState({
						error_message: "Please fill out all form fields.",
						is_submitting: false,
					});
				} else {
					this.props
						.action_function(form_data)
						.then(() => {
							this.setState({
								error_message: "Form has been submitted. Thank you!",
								successfully_submitted: true,
							});
							ga("send", {
								hitType: "event",
								eventCategory: this.props.form_name || "Form",
								eventAction: "SubmitSuccess",
							});
						})
						.catch(() => {
							this.setState({
								error_message:
									"There was a problem. Please check your internet connection and try again.",
								is_submitting: false,
							});
						});
				}
			}
		);
	}

	render() {
		// Allow passing in the URL hash as an ID
		let form_id = this.props.id ? this.props.id.replace("#", "") : undefined;

		// All the form fields
		let fields_html = Object.keys(this.props.fields).map((field_id, index) => {
			let field = this.props.fields[field_id];
			let field_component;

			switch (field.type) {
				case "textarea":
				case "radio":
					field_component = field.type;
					break;
				default:
					field_component = "input";
			}
			if (this.state.successfully_submitted) {
				return (
					<div key={index} className="form-container__inner__row">
						{field.label}: {this.state.fields[field_id]}
					</div>
				);
			} else {
				return (
					<div key={index} className="form-container__inner__row">
						<div>
							<label
								htmlFor={field_id}
								className="form-container__inner__label"
							>
								{field.label}
							</label>

							{field_component === "radio" && (
								<RadioGroup
									id={field_id}
									name={field_id}
									className="form-container__inner__select"
									placeholder={field.placeholder}
									onChange={this.handleInputChange}
									value={this.state.fields[field_id]}
								>
									{field.options.map((option) => {
										return (
											<StyledRadioField
												key={option.value}
												{...option}
												is_submitting={this.state.is_submitting}
											/>
										);
									})}
								</RadioGroup>
							)}
							{field_component === "textarea" && (
								<textarea
									id={field_id}
									name={field_id}
									className="form-container__inner__textarea"
									placeholder={field.placeholder}
									onChange={this.handleInputChange}
									value={this.state.fields[field_id]}
									disabled={this.state.is_submitting}
								/>
							)}
							{field_component === "input" && (
								<input
									id={field_id}
									name={field_id}
									type={field.type}
									className="form-container__inner__textbox"
									placeholder={field.placeholder}
									value={this.state.fields[field_id]}
									onChange={this.handleInputChange}
									disabled={this.state.is_submitting}
								/>
							)}
						</div>
					</div>
				);
			}
		});

		return (
			<div className="form-container">
				<form
					className="form-container__inner form-container__inner--slide"
					id={form_id}
					method="post"
					action={this.props.action_path}
					onSubmit={this.handleSubmit}
				>
					{this.props.success_message && (
						<div className="success-message">{this.props.success_message}</div>
					)}
					<h1 className="form-container__inner__title">{this.props.title}</h1>

					{fields_html}

					{this.state.error_message && (
						<div className="message-container">
							<div className="error-message">{this.state.error_message}</div>
						</div>
					)}

					{!this.state.successfully_submitted && (
						<div className="form-container__inner__row">
							<input
								className="button button--info"
								type="submit"
								value={this.props.action_button}
								disabled={this.state.is_submitting}
							/>
							<div className="privacy-notice">
								<span>Learn more about how we use your data in our </span>
								<a href="/privacy" target="_blank">
									privacy policy
								</a>
							</div>
						</div>
					)}
				</form>
				<div className="form-container__info form-container__info--slide hidden-form-element"></div>
			</div>
		);
	}
}

export default Form;
