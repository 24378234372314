import * as React from "react";
import { enableUniqueIds } from "react-html-id";

import "./CurrencyTextInput.scss";

export default class CurrencyTextInput extends React.Component<any, any> {
	private nextUniqueId: any;
	private lastUniqueId: any;
	private text_input;

	constructor(props) {
		super(props);
		enableUniqueIds(this);

		this.selectInput = this.selectInput.bind(this);
	}

	public selectInput() {
		this.text_input.select();
	}

	public focusInput() {
		// Wrapped in a timeout as a hackish way to ensure the component has rendered
		setTimeout(() => {
			this.text_input.focus();
		}, 10);
	}

	render() {
		return (
			<label
				htmlFor={this.nextUniqueId()}
				className="currency-text-input"
				style={this.props.style}
			>
				<span className="currency-text-input__symbol">$</span>
				<input
					id={this.lastUniqueId()}
					ref={(input) => {
						this.text_input = input;
					}}
					className="currency-text-input__input"
					type="number"
					name={this.props.input_name}
					value={this.props.input_value}
					onChange={this.props.onChange}
					onFocus={this.selectInput}
				/>
			</label>
		);
	}
}
