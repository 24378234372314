import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import LabelledContentItem from "../../components/LabelledContentItem/LabelledContentItem";

import text from "../../locales/en-us";

import "./Mission.scss";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

export default class Mission extends React.Component<any, any> {
	render() {
		return (
			<section className="mission-page">
				<Banner images={banner_images} text={text.mission.banner.main} />

				<div className="restricted-container restricted-container--padded">
					<LabelledContentItem
						title="Our Mission:"
						items={[text.mission.content.mission_statement]}
						emphasis={true}
						no_background={true}
					/>

					<section className="mission-points">
						<ul className="labelled-content-list">
							{text.mission.labelled_content_items.map((item, index) => {
								return (
									<LabelledContentItem
										key={index}
										title={item.title}
										items={item.items}
									/>
								);
							})}
						</ul>
					</section>
				</div>
			</section>
		);
	}
}
