import * as React from "react";
import { Link } from "react-router-dom";
import "./Alert.scss";

export default class Alert extends React.Component<any, any> {
	render() {
		return (
			<div className="alert">
				{this.props.label && (
					<span
						style={{
							fontWeight: "bold",
							marginRight: "12px",
						}}
					>
						{this.props.label}
					</span>
				)}
				{this.props.children}
			</div>
		);
	}
}
