// Google Analytics global variable
declare var ga: any;

import * as React from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";

import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import RadioButton from "../../components/RadioButton/RadioButton";
import CurrencyTextInput from "../../components/CurrencyTextInput/CurrencyTextInput";

import "./DonationForm.scss";

let monthly_donation_amounts = [10, 20, 35, 50];
let one_time_donation_amounts = [25, 50, 100, 500];

let default_amounts = {
	monthly: 20,
	"one-time": 50,
};

export default class DonationForm extends React.Component<any, any> {
	private other_amount_input;

	constructor(props) {
		super(props);
		this.state = {
			fields: {
				donation_amount: default_amounts.monthly,
				donation_type: "monthly",
				donation_amount_other: 0,
			},
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleOtherClick = this.handleOtherClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	private handleInputChange(event) {
		const target = event.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		if (name === "donation_amount_other") {
			let int_value = parseInt(value);
			value =
				int_value !== undefined && !isNaN(int_value) ? parseInt(value) : "";
		}

		var newState;
		if (
			name === "donation_type" &&
			target.value !== this.state.fields.donation_type
		) {
			newState = update(this.state, {
				fields: {
					[name]: { $set: value },
					donation_amount: { $set: default_amounts[target.value] },
				},
			});
		} else {
			newState = update(this.state, {
				fields: {
					[name]: { $set: value },
				},
			});
		}

		this.setState(newState);
	}

	handleOtherClick(event) {
		this.other_amount_input.focusInput();
	}

	// Register Google Analytics even when form is submitted
	handleFormSubmit() {
		ga("send", {
			hitType: "event",
			eventCategory: "Donation",
			eventAction: "Submitted",
		});
	}

	render() {
		let donation_amounts =
			this.state.fields.donation_type === "monthly"
				? monthly_donation_amounts
				: one_time_donation_amounts;
		let paypal_cmd =
			this.state.fields.donation_type === "monthly"
				? "_xclick-subscriptions"
				: "_donations";

		let donation_amount =
			this.state.fields.donation_amount === "other"
				? this.state.fields.donation_amount_other
				: this.state.fields.donation_amount;
		return (
			<div id={this.props.id} className="donation-form">
				<h2 className="donation-form__heading">Donation Amount</h2>
				<h3 className="donation-form__subheading">
					Please select an amount for your tax-deductable donation.
				</h3>

				<ToggleSwitch
					input_name="donation_type"
					input1={{
						value: "monthly",
						text: "Monthly",
						checked: this.state.fields.donation_type === "monthly",
					}}
					input2={{
						value: "one-time",
						text: "One-time",
						checked: this.state.fields.donation_type === "one-time",
					}}
					onChange={this.handleInputChange}
				/>

				<div className="donation-form__button-group">
					{donation_amounts.map((amount) => {
						return (
							<RadioButton
								key={amount}
								input_name="donation_amount"
								input_value={amount}
								label_text={`$${amount}`}
								onChange={this.handleInputChange}
								checked={this.state.fields.donation_amount == amount}
							/>
						);
					})}

					<div>
						<RadioButton
							input_name="donation_amount"
							input_value="other"
							label_text="Other"
							onChange={this.handleInputChange}
							onClick={this.handleOtherClick}
						/>
						<CurrencyTextInput
							input_name="donation_amount_other"
							style={{
								display:
									this.state.fields.donation_amount === "other" ? null : "none",
							}}
							ref={(input) => {
								this.other_amount_input = input;
							}}
							input_value={this.state.fields.donation_amount_other}
							onChange={this.handleInputChange}
						/>
					</div>
				</div>

				<form
					action="https://www.paypal.com/cgi-bin/webscr"
					method="post"
					target="_top"
					onSubmit={this.handleFormSubmit}
				>
					<input type="hidden" name="cmd" value={paypal_cmd} />
					<input type="hidden" name="business" value="4BYXC6LJQZ8PY" />
					{this.state.fields.donation_type === "monthly" && (
						<div>
							<input type="hidden" name="a3" value={donation_amount} />
							<input type="hidden" name="p3" value={1} />
							<input type="hidden" name="t3" value="M" />
							<input type="hidden" name="src" value={1} />
						</div>
					)}
					<input type="hidden" name="amount" value={donation_amount} />
					<input
						type="submit"
						className="button button--emphasis"
						name="submit"
						value="Continue"
					/>
					<img
						alt=""
						src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
						width="1"
						height="1"
					/>
				</form>

				<div>
					<a
						className="light-text-link"
						href="https://www.paypal.com/cgi-bin/customerprofileweb?cmd=_manage-paylist"
					>
						Manage your current monthly donations
					</a>
				</div>
			</div>
		);
	}
}
