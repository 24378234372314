import * as React from "react";
import { Link } from "react-router-dom";

import TextDocument from "../../components/TextDocument/TextDocument";

import text from "../../locales/en-us";

import "./Privacy.scss";

export default class Privacy extends React.Component<any, any> {
	render() {
		let html = `
			<h2>
				Privacy Policy
			</h2>

			<p>Last updated: March 21, 2024</p>

			<p>
				The Awesome Spirit Of Wildlife ("TASOW") believes your privacy is important. The purpose of this policy is to help you understand what data we collect, why we collect it, and how we use it.
			</p>

			<h3>
				Information we collect
			</h3>

			<ul>
				<li>
					<strong>Information you give us.</strong> For example, when you fill out a form on our site, we may ask you for personal information. This may include information like your name, email address, phone number, etc.
				</li>
				<li>
					<strong>Information obtained through the use of our services</strong>
					 <ul>
						<li>
							<strong>Device, browser, and connection information.</strong> For example, we may collect information such as your internet protocol address, cookies that may uniquely identify your browser, information on how you used our services, and other information relating to your device, browser, or connection.
						</li>

						<li>
							<strong>Location information.</strong> We may collect information about your location, obtained through GPS, your IP address, or other sensors.
						</li>

						<li>
							<p>
							<strong>Cookies.</strong> We may cookies to uniquely identify your browser of device. Cookies are not required to use our services. You may disable cookies in your browser before using our services.
							</p>
							<ul>
								<li>
									<strong>Google Analytics.</strong> We use a service called "Google Analytics" that utilizes cookies to collect information about the usage of our services. We use this information solely for the purpose of improving our services. We do not combine this information with any other information that is collected through our services. For more information on how "Google Analytics" uses your information, please refer to the <a href="http://www.google.com/analytics/terms/us.html">Google Analytics Terms of Service</a> and the <a href="http://www.google.com/policies/privacy/">Google Privacy Policy</a>.
								</li>
							</ul>
						</li>
					 </ul>
				</li>
			</ul>



			<h3>
				How we use information we collect
			</h3>

			<ul>
				<li>
					We strive to only retain personal information as long as necessary for it to be used for it's intended purpose. The duration of retention will be determined solely at our discretion, based on what we determine to be reasonable.
				</li>
			</ul>



			<h3>
				Information we share
			</h3>

			<ul>
				<li>
					<strong>With your consent.</strong> In general we will only share your information with a third party if we have received your consent to do so. By using this site you consent to the use of your information as outlined in this Privacy Policy.
				</li>
				<li>
					<strong>For legal purposes.</strong> We may share information if we believe it is in the best legal interest of TASOW or to protect the safety and security of TASOW, the users of our services, or the general public as required or permitted by law.
				</li>
			</ul>
		`;

		return (
			<section className="terms-page">
				<TextDocument html={html}></TextDocument>
			</section>
		);
	}
}
