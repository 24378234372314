import "./favicon.ico";

import * as React from "react";
import {
	BrowserRouter as Router,
	Route,
	Link,
	Switch,
	Redirect,
} from "react-router-dom";
//import { renderRoutes } from "react-router-config";

import { renderRoutes } from "./util/renderRoutes";
import { TransitionGroup, Transition } from "react-transition-group";
import anime from "animejs/lib/anime.es.js";

import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";

import store from "./store/index";

var scrollToElement;

// Adds a class that allows a page element to transition in/out correctly
function makeElementTransitionable(dom_el) {
	dom_el.classList.add("transition-page");
}

export default class App extends React.Component<any, any> {
	static defaultProps: any;
	private enter_duration = 1100;
	private exit_duration = 800;
	private latest_animation;

	constructor(props) {
		super(props);
		this.state = {
			in: true,
			footer_margin: undefined,
		};

		this.onEnter = this.onEnter.bind(this);
		this.onExit = this.onExit.bind(this);
	}

	componentDidMount() {
		// This needs to be required here so that it is not loaded on the server-side
		scrollToElement = require("scroll-to-element");
	}

	// Handler for when a new route has been loaded
	onEnter(dom_el) {
		makeElementTransitionable(dom_el);

		// If the last animation hasn't finished, pause it so it's "finished"
		// promise will never be called.
		if (this.latest_animation) {
			this.latest_animation.pause();
			this.resetPageStyles(dom_el);
		}

		let animation = anime({
			targets: dom_el,
			translateY: ["-105%", 0],
			duration: this.enter_duration,
			elasticity: 100,

			// Ensure element's translateX doesn't move
			translateX: ["-50%", "-50%"],
		});

		// Cleanup once transition is complete
		animation.finished.then(() => {
			this.resetPageStyles(dom_el);
			// Make footer flow with the page now that transition has completed
			dom_el.classList.remove("transition-page");
			this.setState({ footer_margin: 0 });
		});

		this.latest_animation = animation;
		this.setState({ footer_margin: dom_el.offsetHeight * 2 });
	}

	// Handler for when an old route is being removed
	onExit(dom_el: HTMLElement | undefined) {
		makeElementTransitionable(dom_el);

		anime({
			targets: dom_el,
			translateY: [0, "200%"],
			duration: this.exit_duration,
			elasticity: 0,

			// This makes the opacity fade to 0 in half the time it take it to
			// translateY
			opacity: [100, 0, 0],

			// Ensure element's translateX doesn't move
			translateX: ["-50%", "-50%"],
		});

		scrollToElement(".main-header", { duration: 300 });
	}

	// Ensure element doesn't have any lingering transition styles
	resetPageStyles(dom_el) {
		dom_el.style = "";
	}

	render() {
		return (
			<div>
				<Nav />
				<main id="body-container">
					<TransitionGroup>
						<Transition
							key={this.props.location.pathname}
							in={this.state.in}
							timeout={{
								enter: this.enter_duration,
								exit: this.exit_duration,
							}}
							onEnter={this.onEnter}
							// @ts-ignore
							onExit={this.onExit}
						>
							{renderRoutes(
								this.props.route.routes,
								{},
								{ location: this.props.location, key: this.props.location.key }
							)}
						</Transition>
					</TransitionGroup>
				</main>

				<Footer top_margin={this.state.footer_margin} />

				<link
					href="https://fonts.googleapis.com/css?family=Roboto+Condensed"
					rel="stylesheet"
					type="text/css"
				/>
				<link
					href="https://fonts.googleapis.com/css?family=Source+Sans+Pro"
					rel="stylesheet"
					type="text/css"
				/>
			</div>
		);
	}
}
