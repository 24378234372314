import axios from "axios";
import { ContactUsInterface } from "../../../../schemas/ContactUsSchema";

export function postContactUsForm(form_data: ContactUsInterface) {
	return axios({
		method: "post",
		url: "/api/contactUs",
		data: form_data,
	});
}
