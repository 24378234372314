import * as React from "react";
import { Link } from "react-router-dom";
import "./Banner.scss";

export default class Banner extends React.Component<any, any> {
	render() {
		let action_button = this.props.action_button;
		if (action_button) {
			action_button = React.isValidElement(action_button) ? (
				action_button
			) : (
				<Link
					to={this.props.action_button.path}
					className="button button--info"
				>
					{this.props.action_button.text}
				</Link>
			);
		}

		return (
			<section
				className={
					"banner " +
					(this.props.no_margin !== undefined ? "banner--no-margin" : "")
				}
			>
				<div className="image-wrapper">
					<picture>
						<source
							media="(min-width: 960px)"
							srcSet={`${this.props.images.large} 1200w`}
							sizes="(max-width: 1199px) 100vw, 1200px,"
						/>
						<source
							media="(min-width: 475px)"
							srcSet={`${this.props.images.medium} 960w,`}
						/>
						<source srcSet={`${this.props.images.small} 475w,`} />
						<img
							src={this.props.images.large}
							className="banner__image"
							alt=""
						/>
					</picture>
				</div>
				<div className="banner__edge-gradient-overlay"></div>
				<section className="banner__bottom-overlay">
					{this.props.text && (
						<h2 className="banner__bottom-overlay__title">{this.props.text}</h2>
					)}

					{this.props.secondary_text && (
						<h3 className="banner__bottom-overlay__subtitle">
							{this.props.secondary_text}
						</h3>
					)}

					{action_button}
				</section>
			</section>
		);
	}
}
