import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import ContentTile from "../../components/ContentTile/ContentTile";
import Form from "../../components/Form/Form";

import {
	EducationalProgramProperties,
	EducationalProgramFields,
} from "../../../schemas/EducationalProgramSchema";
import { postEducationalProgramForm } from "../../store/educational_program_form/ajax/postEducationalProgramForm";
import text from "../../locales/en-us";

import "./Education.scss";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

let tile_images = [
	require("./images/presentation.jpg"),
	require("./images/presentation_animal.jpg"),
];

export default class Education extends React.Component<any, any> {
	render() {
		return (
			<section className="education-page">
				<Banner
					images={banner_images}
					text={text.education.banner.main}
					action_button={{
						path: text.education.banner.action_path,
						text: text.education.banner.action_button,
					}}
				/>

				<div className="restricted-container">
					<section className="split-when-large split-when-large--left">
						<div className="block-list">
							{text.education.content_tiles.map((tile, index) => {
								return (
									<ContentTile
										key={index}
										items={tile.items}
										image={tile_images[index]}
										title={tile.title}
										paragraphs={tile.paragraphs}
									/>
								);
							})}
						</div>
					</section>

					<section className="social-media-tiles split-when-large split-when-large--right">
						<Form
							id={text.education.banner.action_path}
							form_name="ProgramForm"
							validatorTypes={EducationalProgramProperties}
							title={text.education.form.title}
							fields={EducationalProgramFields}
							action_path={text.education.form.action_path}
							action_function={postEducationalProgramForm}
							action_button={text.education.form.action_button}
						/>
					</section>
				</div>
			</section>
		);
	}
}
