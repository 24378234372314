import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import ContentTile from "../../components/ContentTile/ContentTile";

import text from "../../locales/en-us";

import "./Rescue.scss";
import Alert from "../../components/Alert/Alert";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

let animal_image = require("./images/snek.jpg");

export default class Rescue extends React.Component<any, any> {
	render() {
		return (
			<section className="rescue-page">
				<Banner images={banner_images} text={text.rescue.banner.main} />
				<div className="restricted-container">
					<Alert label="Attention:">
						Due to the amount of requests we have been receiving to take in
						rescue animals, our volunteer network in both PA and TN is currently
						at capacity. We are working as hard as we can to make room for new
						arrivals. To help us increase this capacity and save more animals,
						please visit our <Link to="./donate">DONATE page</Link>. We
						apologize for the inconvenience and thank you for your patience.
					</Alert>
					<div className="block-list">
						<ContentTile
							full_width={true}
							title={text.rescue.why.title}
							paragraphs={text.rescue.why.paragraphs}
							image={animal_image}
						/>
					</div>
				</div>
			</section>
		);
	}
}
