const text: any = {
	global: {
		placeholders: {
			name: "Steve Irwin",
			email: "steve@example.com",
			address: "1638 Steve Irwin Way",
			full_address: "Street, city, state, etc.",
			"zip-code": "16057",
			city: "Slippery Rock",
			state: "PA",
			phone: "(123) 123-1234",
		},
		learn_more: "Learn more",
	},
	current_page_title:
		"TASOW | The Awesome Spirit Of Wildlife (Ecology Education)",
	current_page_description:
		"Our mission is to help communities create a sustainable future for all living things through education, biodiversity preservation, and a culture of appreciation for the natural world.",
	page_titles: {
		home: "TASOW | Education - Conservation - Rehabilitation",
		mission: "Our Mission | TASOW",
		chapters: "Our Chapters and Locations | TASOW",
		western_pennsylvania:
			"Western Pennsylvania Exotic Animal Rescue and Conservation Education | TASOW",
		eastern_tennessee:
			"Eastern Tennessee Exotic Animal Rescue and Conservation Education | TASOW",
		pittsburgh:
			"Pittsburgh, PA Exotic Animal Rescue and Conservation Education | TASOW",
		slippery_rock:
			"Slippery Rock, PA Exotic Animal Rescue and Conservation Education | TASOW",
		chattanooga:
			"Chattanooga, TN Exotic Animal Rescue and Conservation Education | TASOW",
		education: "Environmental Biodiversity Education | TASOW",
		rescue: "Exotic Animal Rescue | TASOW",
		store: "Store | TASOW",
		volunteer: "Volunteer to help exotic animals | TASOW",
		contact: "Contact Us | TASOW",
		donate: "Donate | TASOW",
		donation_complete: "Thank you for your donation! | TASOW",
		manage_donations: "Manage Donations | TASOW",
		terms: "Terms | TASOW",
		privacy: "Privacy | TASOW",
		press: "Press | TASOW",
	},
	nav: {
		mission: "Our Mission",
		locations: "Our Locations",
		education: "Education",
		rescue: "Rescue",
		store: "Store",
		volunteer: "Volunteer",
		contact: "Contact Us",
		donate: "Donate",
		manage_donations: "Manage Donations",
		terms: "Terms",
		privacy: "Privacy",
		press: "Press",
	},
	home: {
		banner: {
			main:
				"We believe all people have the power to coexist with the natural world.",
			chattanooga:
				"TASOW is expanding to Chattanooga, Tennessee and we are looking for volunteers!",
			action_button: "Sign up to Volunteer",
			action_path: "/volunteer",
		},
		tile_buttons: [
			{
				title: "Give",
				description: "Help us go further",
				route: "donate",
				icon: require("../pages/Home/images/heart.svg"),
			},
			{
				title: "Learn",
				description: "Book an educational program",
				route: "education",
				icon: require("../pages/Home/images/lightbulb.svg"),
			},
			{
				title: "Join",
				description: "Volunteer to help",
				route: "volunteer",
				icon: require("../pages/Home/images/torsos.svg"),
			},
		],
		twitter: {
			show_more: "show more tweets",
		},
		content_tiles: [
			{
				title: "Educational Programs",
				paragraphs: [
					"Our educational programs aim to teach the importance of conservation and shed light on the problems caused by the mistreatment of living things.",
				],
				route: "education",
				image: require("../pages/Home/images/educational_programs.jpg"),
			},
			{
				title: "Animal Rescue",
				paragraphs: [
					"We provide rescue services for exotic animals and use their stories to educate others so future mistreatment can be avoided.",
				],
				route: "rescue",
				image: require("../pages/Home/images/animal_rescue.jpg"),
			},
			{
				title: "Our Volunteers",
				paragraphs: [
					"We depend on a group of amazing people who dedicate their time to helping our cause. We need people like you to continue our mission of conservation.",
				],
				route: "volunteer",
				image: require("../pages/Home/images/volunteers.jpg"),
			},
		],
	},
	mission: {
		banner: {
			main:
				"You have the power to change your community for the better. We want to help.",
		},
		content: {
			mission_statement:
				"Our mission is to help communities create a sustainable future  for all living things through education, biodiversity preservation, and a culture of appreciation for the natural world.",
		},
		labelled_content_items: [
			{
				title: "Education",
				items: [
					"Education is at the core of everything we do.",
					"We believe when people learn the importance of biodiversity and ecological issues they will be motivated to work toward a sustainable future.",
					"We want to help you teach your community.",
				],
			},
			{
				title: "Biodiversity Preservation",
				items: [
					"Actions speak louder than words. We live what we teach.",
					"We believe the best way to teach is by showing what can be done. By incorporating educational elements into ecological initiatives, you double the benefit.",
					"We want to help your community create programs that benefit your ecological well-being and teach the members of your community why it matters.",
				],
			},
			{
				title: "Cultural Appreciation of Living Things",
				items: [
					"Cultural beliefs often result in causing harm to ecosystems. There is no reason cultural beliefs can't do the opposite.",
					"We believe the key to a better planet is an appreciation for all things living on our planet.",
					"We want to help others understand this belief.",
				],
			},
			{
				title: "Who is TASOW?",
				items: [
					"We are simply people who accepted the challenge of teaching others how to live for the future. You can join us.",
					"TASOW was started in 2004 by a few high school students. We believe young people deserve the chance to learn and the opportunity to teach others.",
					"We are currently headquartered in Western Pennsylvania in the town of Slippery Rock.",
					"TASOW is a government-certified 501(c)3 non-profit.",
				],
			},
		],
		blocks: {
			education: {
				title: "Education",
				summary:
					"We (humanity) have a huge impact on our environment. Our collective knowledge and understanding will determine our future, and TASOW strives to ensure we make the best decisions for our planet.",
			},
			conservation: {
				title: "Conservation",
				summary:
					"There are millions of species living on our planet. TASOW aims to keep it that way.",
			},
			rehabilitation: {
				title: "Rehabilitation",
				summary:
					"Our (humanity’s) actions have negatively impacted many living things. TASOW endeavors to counteract these actions.",
			},
		},
	},
	chapters: {
		banner: {
			main: "TASOW is now in Eastern Tennessee!",
			secondary:
				"We now have chapters based in Pittsburgh, PA and Chattanooga, TN.",
		},
		content_tiles: [
			{
				title: "Western Pennsylvania",
				paragraphs: [
					"Our organization was founded an hour north of Pittsburgh in the town of Slippery Rock, Pennsylvania.",
					"We have since expanded to the rest of Western PA, mainly operating in the greater Pittsburgh area.",
				],
				to: "/chapters/western-pennsylvania",
				action_button: "Learn more",
			},
			{
				title: "Eastern Tenneessee",
				paragraphs: [
					"In 2021, we offially opened our second chapter in Chattanooga, Tennessee.",
					"We are currently searching for our initial volunteers and organizations that are interested in booking educational programs in the greater Chattanooga area.",
				],
				to: "/chapters/eastern-tennessee",
				action_button: "Learn more",
			},
		],
	},
	western_pennsylvania: {
		banner: {
			main: "Western PA Chapter",
			secondary:
				"We have been providing educational programs and exotic animal rescue services in Western PA for over a decade.",
		},
		content_tiles: [
			{
				title: "Become a Volunteer",
				paragraphs: [
					"Our Western Pennsylvania chapter is 100% volunteer-run and we are looking for dedicated people like you!",
					"Join today to gain experience working with rescue animals and educating the public on conservation and animal welfare.",
				],
				to: "/volunteer",
				image: require("../pages/WesternPennsylvania/images/volunteers.jpg"),
				action_button: "Sign Up to Volunteer Today!",
			},
			{
				title: "Book an Educational Program",
				paragraphs: [
					"Are you a teacher or group leader in Western PA looking for a fun and educational live-animal presentation?",
					"TASOW rescues exotic animals like snakes, lizard, and turtles, which we then use to help teach others.",
				],
				to: "/education",
				image: require("../pages/WesternPennsylvania/images/educational_programs.jpg"),
				action_button: "Book Us Today!",
			},
		],
		location_links: [
			{ text: "Pittsburgh", route: "/chapters/pittsburgh-pennsylvania" },
			{ text: "Slippery Rock", route: "/chapters/slippery-rock-pennsylvania" },
		],
	},
	eastern_tennessee: {
		banner: {
			main: "TASOW is now in Eastern Tennessee!",
			secondary:
				"As of 2021 TASOW’s exotic animal rescue and educational efforts have expanded to the state of Tennessee!",
		},
		content_tiles: [
			{
				title: "Become a Volunteer",
				paragraphs: [
					"You can be one of our first volunteers in Tennessee!",
					"TASOW has officially opened a new chapter in Eastern TN to educate the public on conservation and animal welfare. We are currently building our initial group of passionate volunteers, so sign up today!",
				],
				to: "/volunteer",
				image: require("../pages/EasternTennessee/images/volunteers.jpg"),
				action_button: "Sign Up to Volunteer Today!",
			},
			{
				title: "Book an Educational Program",
				paragraphs: [
					"Our live-animal presentations are now available in Eastern TN!",
					"We'll bring rescued snakes, lizard, turtles, and more to your location and provide a fun, interactive, and educational show.",
				],
				to: "/education",
				image: require("../pages/EasternTennessee/images/educational_programs.jpg"),
				action_button: "Book Us Today!",
			},
		],
		location_links: [
			{ text: "Chattanooga", route: "/chapters/chattanooga-tennessee" },
		],
	},

	pittsburgh: {
		banner: {
			main: "Pittsburgh Chapter",
			secondary:
				"We have been providing educational programs and exotic animal rescue services in the Pittsburgh area for over a decade.",
		},
		content_tiles: [
			{
				title: "Become a Volunteer",
				paragraphs: [
					"Our Pittsburgh chapter is 100% volunteer-run and we are looking for dedicated people like you!",
					"Join today to gain experience working with rescue animals and educating the public on conservation and animal welfare.",
				],
				to: "/volunteer",
				image: require("../pages/Pittsburgh/images/volunteers.jpg"),
				action_button: "Sign Up to Volunteer Today!",
			},
			{
				title: "Book an Educational Program",
				paragraphs: [
					"Are you a teacher or group leader in Pittsburgh looking for a fun and educational live-animal presentation?",
					"TASOW rescues exotic animals like snakes, lizard, and turtles, which we then use to help teach others.",
				],
				to: "/education",
				image: require("../pages/Pittsburgh/images/educational_programs.jpg"),
				action_button: "Book Us Today!",
			},
		],
	},
	slippery_rock: {
		banner: {
			main: "Slippery Rock Chapter",
			secondary:
				"TASOW was founded in Slippery Rock in 2009, and we're still here.",
		},
		content_tiles: [
			{
				title: "Become a Volunteer",
				paragraphs: [
					"Our Slippery Rock chapter is 100% volunteer-run and we are looking for dedicated people like you!",
					"Whether you're an SRU student looking for experience working with animals or a local who is interested in our cause, we encourage you to apply.",
					"Join today to help rescue animals and educate the public on conservation and exotic animal welfare.",
				],
				to: "/volunteer",
				image: require("../pages/SlipperyRock/images/volunteers.jpg"),
				action_button: "Sign Up to Volunteer Today!",
			},
			{
				title: "Book an Educational Program",
				paragraphs: [
					"Are you a teacher or group leader in Slippery Rock looking for a fun and educational live-animal presentation?",
					"TASOW rescues exotic animals like snakes, lizard, and turtles. We then utilize these animals to teach about their importance.",
				],
				to: "/education",
				image: require("../pages/SlipperyRock/images/educational_programs.jpg"),
				action_button: "Book Us Today!",
			},
		],
	},

	chattanooga: {
		banner: {
			main: "TASOW is now in Chattanooga!",
			secondary:
				"As of 2021 TASOW’s exotic animal rescue and educational efforts are now available in the greater Chattanooga area!",
		},
		content_tiles: [
			{
				title: "Become a Volunteer",
				paragraphs: [
					"You can be one of our first volunteers in Chattanooga!",
					"TASOW has officially opened a new chapter in Chattanooga, TN to educate the public on conservation and animal welfare. We are currently building our initial group of passionate volunteers, so sign up today!",
				],
				to: "/volunteer",
				image: require("../pages/Chattanooga/images/volunteers.jpg"),
				action_button: "Sign Up to Volunteer Today!",
			},
			{
				title: "Book an Educational Program",
				paragraphs: [
					"Our live-animal presentations are now available in Chattanooga TN!",
					"We'll bring rescued snakes, lizard, turtles, and more to your location and provide a fun, interactive, and educational show.",
				],
				to: "/education",
				image: require("../pages/Chattanooga/images/educational_programs.jpg"),
				action_button: "Book Us Today!",
			},
		],
		location_links: [
			{ text: "Chattanooga", route: "chapters/chattanooga-tenneessee" },
		],
	},
	education: {
		banner: {
			main:
				"Through educational programs we strive to inspire an appreciation for ecology and an understanding of the importance of all our world's creatures.",
			action_button: "Book a Program Now",
			action_path: "#book-a-program",
		},
		content_tiles: [
			{
				title: "What to expect",
				items: [
					"You will learn about the ecological importance of reptiles, amphibians, and other awesome creatures.",
					"Everyone gets a chance to touch, hold, and interact with our animals.",
					"Our programs generally last about an hour.",
					"If you would like us to focus on a specific topic, we will tailor our content to meet your needs.",
				],
			},
			{
				title: "The animals we bring",
				paragraphs: [
					"Many of the animals that you see at our programs were rescued from abuse or neglect.",
					"Some of the animals you might see are:",
				],
				items: [
					"Red-tail Boa",
					"Carpet Python",
					"Bearded Dragon",
					"Geckos",
					"African Sulcata Tortoise",
					"Ball Python",
				],
			},
			{
				title: "Booking requirements",
				items: [
					"$150 is the minimum suggested donation amount for an hour and a half long educational program.",
					"When you make a program request, we will send you a recommended donation amount based on travel time and the length of the program.",
					"We recommend that you place your booking request at least 1 month in advance of the date of the program.",
				],
			},
		],
		form: {
			title: "Book a Program",
			success_message:
				"Thank you for your program request. We will contact you soon to verify our availablity for the time you requested.",
			sending_message: "Sending program request...",
			error_message:
				"There was a problem sending your request. Please try again.",
			fields: [
				{
					id: "name",
					name: "Name",
					placeholder: "Steve Irwin",
					type: "text",
					required: true,
				},
				{
					id: "email",
					name: "Email",
					placeholder: "steve@example.com",
					type: "email",
					required: true,
				},
				{
					id: "phone",
					name: "Phone Number",
					placeholder: "(123) 123-1234",
					type: "tel",
					required: true,
				},
				{
					id: "date",
					name: "Desired Date",
					placeholder: "",
					type: "date",
					required: true,
				},
				{
					id: "start_time",
					name: "Start Time",
					placeholder: "",
					type: "time",
					required: true,
				},
				{
					id: "end_time",
					name: "End Time",
					placeholder: "",
					type: "time",
					required: true,
				},
				{
					id: "address",
					name: "Full Address where program will be held",
					placeholder: "Street, city, state, etc.",
					type: "textarea",
					required: true,
				},
				{
					id: "additional_info",
					name: "Additional Info",
					placeholder: "Anything else we need to know?",
					type: "textarea",
					required: false,
				},
			],
			action_button: "Book Program",
			action_path: "api/bookAProgram",
		},
	},
	rescue: {
		banner: {
			main: "We believe all living things deserve to be treated with respect",
		},
		why: {
			title: "Why we rescue exotic animals",
			paragraphs: [
				"We strive to be a voice for the misunderstood and underrepresented creatures of the world. Lack of knowledge about exotic animals has led to widespread irresponsible practices with breeding, pet dealing, and pet ownership. This results in a large number of animals being neglected and abused.",
				"Since these are exotic animals we do not have the option of releasing them into the wild. Our only option is to give them a home in our rescue where they will be treated with respect.",
				"Through our rescue, we not only help the animals affected by these problems, but also use their stories to help teach others not to make the same mistakes in the future.",
			],
			action_button: "Request Rescue",
		},
		sponsor: {
			title: "Sponsor an Animal",
			paragraphs: {
				"0":
					"It takes a lot of resources to care for our rescue animals. When you sponsor one of our animals with a monthly donation, you are ensuring that we can provide the animal with the best possible care. Scroll down to see who you can sponsor.",
			},
		},
	},
	contact: {
		banner: {
			main: "Have something to say? We'd love to hear from you.",
			action_button: "Send us a message",
			action_path: "#contact-form",
		},
		blocks: {
			general: {
				title: "Send us a message",
				items: {
					"0":
						"Your questions and comments are important to us, and we will do our best to respond quickly.",
				},
			},
		},
		content_tiles: [
			{
				title: "Send us a message",
				items: [
					"Your questions and comments are important to us, and we will do our best to respond quickly.",
				],
				image: "home/volunteers.jpg",
			},
		],
		form: {
			title: "Contact Us",
			success_message:
				"Thank you! Your message has been sent. We will do our best to respond as soon as possible.",
			sending_message: "Sending...",
			error_message:
				"There was a problem sending your message. Please try again.",
			fields: [
				{
					id: "name",
					name: "Name",
					placeholder: "Steve Irwin",
					type: "text",
					required: true,
				},
				{
					id: "email",
					name: "Email",
					placeholder: "steve@example.com",
					type: "email",
					required: true,
				},
				{
					id: "message",
					name: "Message",
					placeholder: "Speak your mind",
					type: "textarea",
					required: true,
				},
			],
			action_button: "Send Message",
			action_path: "api/contact-us",
		},
	},
	volunteer: {
		banner: {
			main:
				"Your actions matter. Volunteer to make a real impact in your community.",
			action_button: "Join a crew now",
			action_path: "#volunteer-form",
		},
		form: {
			title: "Volunteer Sign-up Form",
			success_message:
				"Thank you for applying to volunteer. We will contact you soon with further details.",
			sending_message: "Sending volunteer application...",
			error_message:
				"There was a problem sending your application. Please try again.",
			fields: [
				{
					id: "name",
					name: "Name",
					placeholder: "Steve Irwin",
					type: "text",
					required: true,
				},
				{
					id: "email",
					name: "Email",
					placeholder: "steve@example.com",
					type: "email",
					required: true,
				},
				{
					id: "phone",
					name: "Phone Number",
					placeholder: "(123) 123-1234",
					type: "tel",
					required: true,
				},
				{
					id: "date-of-birth",
					name: "Date of Birth",
					type: "date",
					required: true,
				},
				{
					id: "location",
					name: "Where do you live?",
					placeholder: "Slippery Rock, PA",
					type: "text",
					required: true,
				},
				{
					id: "why-volunteer",
					name: "Why do you want to volunteer?",
					placeholder: "There are no wrong answers. :-)",
					type: "textarea",
					required: true,
				},
			],
			placeholders: {
				"why-volunteer": "There are no wrong answers. :-)",
			},
			action_button: "Apply to volunteer",
			action_path: "api/volunteer",
		},
		content_tiles: [
			{
				title: "Become a volunteer today!",
				items: [
					"As a volunteer, you will have the opportunity to work with our animals and help teach the public about the ecological importance of reptiles and amphibians.",
				],
			},
			{
				title: "Why you should volunteer",
				items: [
					"You can have a real impact on your community by helping people understand the importance of ecological issues and the humane treatment of exotic animals.",
					"It is a great experience to work with some really cool animals.",
					"Volunteering can be a great addition to your resume.",
					"It's a lot of fun, and you will meet a lot of cool people who all care about TASOW's mission.",
				],
			},
		],
	},
	donate: {
		paragraphs: [
			"We would like to give a special thanks to everyone who donates to our organization.",
			"We are a non-profit 501(c)(3) organization, and we are currently funded by contributions from generous donors like you. All donations go toward supporting our mission of preserving the natural world through education.",
		],
		banner: {
			main:
				"Your generosity is what allows us to save reptiles and amphibians.",
			action_button: "Donate Now",
			action_path: "#donate-form",
		},
		content_tiles: [
			{
				title: "Saving exotic animals is expensive",
				items: [
					"It takes a lot of money to provide our animals with the healthy and comfortable lives they deserve",
					"On average, caring for one of our rescue animals costs over $400 per year, and will on average cost over $18,000 during its lifetime. (Some animals can live up to 100 years!)",
					"In addition to these normal costs, veterinary bill can easily be hundreds of dollars (as shown in the image).",
				],
			},
			{
				title: "Help us build a rescue facility",
				items: [
					"We are receiving more rescue requests than we can handle. To continue accomplishing our mission, we need an official location to house our animals.",
					"In 2018, we hope to establish a fully functioning facility in the Slippery Rock, PA area that will both improve our ability to care for our rescue animals and provide a location the public can visit to learn more about our cause.",
				],
			},
			{
				title: "Public Education Programs",
				items: [
					"Donations allow us to provide educational programs that help create a world where people understand the importance of ecology and the wellbeing of living things.",
				],
			},
		],
		form: {
			type: {
				title: "What type of donation would you like to make?",
				description:
					"Make a one-time gift or give monthly to support us into the future.",
				monthly: {
					label: "Monthly",
					description:
						"Guarantee your ongoing support by contributing each month.",
				},
				"one-time": {
					label: "One-time",
					description:
						"Your one-time gift will help our mission of education and rescue succeed.",
				},
			},
			levels: {
				title: "What amount would you like to give?",
				description:
					"Your donation will help educate the youth and save animals.",
				choices: [
					{
						amount: "500",
						description:
							"$500 can purchase food for all our rescue animals for over 3 months.",
					},
					{
						amount: "200",
						description:
							"$200 can feed all of our rescue animals for over a month.",
					},
					{
						amount: "100",
						description: "$100 can feed all of our rescue animals for 2 weeks",
					},
					{
						amount: "50",
						description: "$50 can feed two of our rescue animals for 1 month",
					},
					{
						amount: "25",
						description: "$25 will feed one of our rescue animals for 1 month",
					},
				],
				other: {
					placeholder: "other",
				},
			},
			billing: {
				title: "What is your billing info?",
				fields: {
					name: {
						label: "Full Name",
					},
					email: {
						label: "E-mail",
					},
					address: {
						label: "Address",
					},
					"zip-code": {
						label: "Zip Code",
					},
					city: {
						label: "City",
					},
					state: {
						label: "State",
					},
				},
			},
			"credit-card": {
				title: "What is your credit card info?",
				fields: {
					"card-number": {
						label: "Credit Card Number",
						placeholder: "1234 5678 9012 3456",
					},
					"card-expiration": {
						label: "Expiration (MM/YY)",
						placeholder: "MM/YY",
					},
					cvv: {
						label: "CVV Number",
						placeholder: "000",
					},
				},
			},
			"submit-button": "Process Donation",
		},
	},
	donation_complete: {
		title:
			"Thank you for donating to help us save exotic animals and educate the public!",
		paragraphs: [
			"Your transaction has been completed, and a receipt for your purchase has been emailed to you. You may log into your account at <a href='https://www.paypal.com'>www.paypal.com</a> to view details of this transaction.",
			"We are a non-profit 501(c)(3) organization, and we are currently funded by contributions from generous donors like you. All donations go toward supporting our mission of preserving the natural world through education.",
		],
		banner: {
			main:
				"Your generosity is what allows us to save reptiles and amphibians.",
		},
	},
	manage_donations: {
		message:
			"If you are currently making monthly donations to TASOW, you can manage your donations from this page.",
		instructions:
			"In the box below, enter the email address you provided when signing up for monthly donations. We will then email you a link to a page where you can view and change your donations.",
		form: {
			title: "Manage Donations",
			fields: {
				email: "Email",
			},
			submit_button: "Send me the link",
			success_message:
				"Email sent. Please follow the link in the email you receive to manage your donations.",
			sending_message: "Sending email...",
			error_message: "There was a problem sending the email. Please try again.",
		},
	},
	press: {
		logo: {
			title: "Logo",
			download: "Download Logo Images",
		},
		awards: {
			title: "Awards",
			items: [
				"2010 - ORWC Watershed Award (2nd Place, Best Display)",
				"2009 - ORWC Watershed Award (1st Place, Most Innovative Project)",
				"2009 - Earth Friend Award ",
				"2009 - Western Pennsylvania Environmental Award",
				"2008 - Ohio River Watershed Celebration(ORWC) Watershed Award (1st Place, Best Display)",
			],
		},
		leadership: {
			title: "Leadership",
			items: [
				{
					name: "Alex Petridis",
					position: "President",
					bio:
						"One of the founding members of TASOW, Alex has always been an enthusiast on reptiles, amphibians, and wildlife conservation. Originally from Florida, he grew up surrounded by animals and had interest in in Herpetology at a young age. In high school, Alex got involved with the Audubon Society’s “Take-Part” Program and Eastern Box Turtle Conservation Project  which were two of the inspiring catalysts that to the founding of TASOW. He is a recent graduate of Slippery Rock University where he studied Spanish Language and Environmental Science and hopes to use his work with TASOW to return to school for a degree in Wildlife Biology. Alex is also an Eagle Scout and recipient of the William T. Hornaday Award for Conservation.",
				},
				{
					name: "Luis Serafin",
					position: "Board Member & Marketing Coordinator",
				},
			],
		},
	},
	landing_pages: {
		VolunteerReptileRescue: {
			meta: {
				title: "Volunteer with our Reptile Rescue",
				description: "Volunteer with our Reptile Rescue",
			},
			headline1: "YOU can save exotic animals.",
			headline2:
				"Volunteer to join our rescue crew and have a real impact in YOUR community.",
			form: {
				label: "Enter your email to start today:",
				placeholder: "beardie-mama@tasow.org",
				button: {
					text: "Sign me up!",
					path: "/volunteer#volunteer-form",
				},
			},
			lead: "",
			content: {},
		},
	},
};

export default text;
