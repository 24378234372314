import * as React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import text from "../../locales/en-us";

let logos = {
	twitter: require("./images/twitter.svg"),
	facebook: require("./images/facebook.svg"),
	instagram: require("./images/instagram.svg"),
};

export default class Footer extends React.Component<any, any> {
	render() {
		return (
			<footer
				className="main-footer"
				style={{ marginTop: this.props.top_margin }}
			>
				<div className="top-footer-content">
					<nav>
						<ul className="footer-links">
							<li>
								<Link to="/education" className="footer-links__link">
									{text.nav.education}
								</Link>
							</li>
							<li>
								<Link to="/rescue" className="footer-links__link">
									{text.nav.rescue}
								</Link>
							</li>
							<li>
								<Link to="/volunteer" className="footer-links__link">
									{text.nav.volunteer}
								</Link>
							</li>
							<li>
								<Link to="/mission" className="footer-links__link">
									{text.nav.mission}
								</Link>
							</li>
							<li>
								<Link to="/chapters" className="footer-links__link">
									{text.nav.locations}
								</Link>
							</li>
							<li>
								<Link to="/contact-us" className="footer-links__link">
									{text.nav.contact}
								</Link>
							</li>
							<li>
								<Link
									to="/terms"
									className="footer-links__link footer-links__link--subtle"
								>
									{text.nav.terms}
								</Link>
							</li>
							<li>
								<Link
									to="/privacy"
									className="footer-links__link footer-links__link--subtle"
								>
									{text.nav.privacy}
								</Link>
							</li>
							<li>
								<Link
									to="/press"
									className="footer-links__link footer-links__link--subtle"
								>
									{text.nav.press}
								</Link>
							</li>
						</ul>
					</nav>

					<div className="social-media-links">
						<ul>
							<li>
								<a
									href="https://twitter.com/tasow"
									title="TASOW's Twitter Page"
									className="social-media-links__icon"
								>
									<img className="icon" src={logos.twitter} />
								</a>
							</li>
							<li>
								<a
									href="https://www.facebook.com/TASOW"
									title="TASOW's Facebook Page"
									className="social-media-links__icon"
								>
									<img className="icon" src={logos.facebook} />
								</a>
							</li>
							<li>
								<a
									href="https://instagram.com/_tasow"
									title="TASOW's Instagram Page"
									className="social-media-links__icon"
								>
									<img className="icon" src={logos.instagram} />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="copyright">
					&copy; 2024 The Awesome Spirit Of Wildlife
				</div>
			</footer>
		);
	}
}
