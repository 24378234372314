import * as React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

export default class Button extends React.Component<any, any> {
	render() {
		let ComponentName: any = this.props.to === undefined ? "button" : Link;
		let classes = ["button"];
		if (this.props.className) {
			classes.push(this.props.className);
		}
		let modifiers = (this.props.modifiers || []).map((modifier) => {
			return `button--${modifier}`;
		});
		classes = classes.concat(modifiers);
		return (
			<ComponentName className={classes.join(" ")} to={this.props.to}>
				{this.props.children}
			</ComponentName>
		);
	}
}
