// Google Analytics global variable
declare var ga: any;

import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import HeadingBox from "../../components/HeadingBox/HeadingBox";
import MainContent from "../../components/MainContent/MainContent";
import InnerContent from "../../components/InnerContent/InnerContent";

import text from "../../locales/en-us";

import "./VolunteerReptileRescue.scss";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

let tile_images = [
	require("./images/volunteers.jpg"),
	require("./images/volunteering.jpg"),
];

const cta_form = (
	<form className="banner-cta-container">
		{/* <label>{text.landing_pages.VolunteerReptileRescue.form.label}</label>
		<input
			type="text"
			placeholder={text.landing_pages.VolunteerReptileRescue.form.placeholder}
		/> */}

		<Link
			to={text.landing_pages.VolunteerReptileRescue.form.button.path}
			className="button button--info"
			onClick={() => {
				ga("send", {
					hitType: "event",
					eventCategory: "LandingPage",
					eventAction: "CtaClick",
				});
			}}
		>
			{text.landing_pages.VolunteerReptileRescue.form.button.text}
		</Link>
	</form>
);

export default class Volunteer extends React.Component<any, any> {
	render() {
		return (
			<section className="volunteer-reptile-rescue-landing-page">
				<Banner
					images={banner_images}
					text={
						<div>
							<div className="heading-one">
								{text.landing_pages.VolunteerReptileRescue.headline1}
							</div>
							<div className="heading-two">
								{text.landing_pages.VolunteerReptileRescue.headline2}
							</div>
						</div>
					}
					action_button={cta_form}
				/>

				<MainContent>
					<InnerContent>
						<HeadingBox>Your Impact</HeadingBox>
						<article>
							<p className="body-text">
								As a member of our exotic animal rescue volunteer crew, you’ll
								have the opportunity to make a real difference. Your efforts
								will directly contribute to:
							</p>
							<ul>
								<li>
									The thousands of education-hours provided through our
									education programs.
								</li>
								<li>Countless animals saved from neglect and abuse.</li>
								<li>
									An immeasurable cultural impact from encouraging
									sustainability in your community.
								</li>
							</ul>
						</article>

						<HeadingBox>Find your tribe</HeadingBox>
						<article>
							<p className="body-text">
								Love what we do? So does everyone else who volunteers with
								TASOW. That’s why we’re all here.
							</p>
							<p className="body-text">
								We are simply a group of people who genuinely care about the
								well being of animals—no matter how scaley🐍🙂
							</p>
						</article>

						<HeadingBox>Be Awesome</HeadingBox>
						<article>
							<p className="body-text">
								Dedicating your time to a good cause is awesome and other will
								agree.
							</p>
							<p className="body-text">
								Your time dedicated to TASOW isn’t just great for the snakes,
								lizards, and other exotic pets you help save, it’s also an
								admirable gem on your resume.
							</p>

							<p className="body-text">
								Join a volunteer crew near you and help build something you and
								everyone else will be proud of.
							</p>
						</article>

						{cta_form}
					</InnerContent>
				</MainContent>
			</section>
		);
	}
}
