import axios from "axios";

import { invalidateSocialMediaPosts } from "../actions/invalidateSocialMediaPosts";
import { receiveSocialMediaPosts } from "../actions/receiveSocialMediaPosts";

export function fetchSocialMediaPosts(dispatch) {
	dispatch(invalidateSocialMediaPosts());
	return axios({
		method: "get",
		url: "/api/socialMedia",
	}).then((res) => {
		if (res.data) {
			dispatch(receiveSocialMediaPosts(res.data));
		}
	});
}
