import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import ContentTile from "../../components/ContentTile/ContentTile";
import Form from "../../components/Form/Form";

import {
	ContactUsProperties,
	ContactUsFields,
} from "../../../schemas/ContactUsSchema";
import { postContactUsForm } from "../../store/contact_us_form/ajax/postContactUsForm";

import text from "../../locales/en-us";

import "./Contact.scss";
import Alert from "../../components/Alert/Alert";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

let contacts_image = require("./images/volunteers.jpg");

export default class Contact extends React.Component<any, any> {
	render() {
		return (
			<section className="contact-us-page">
				<Banner
					images={banner_images}
					text={text.contact.banner.main}
					action_button={{
						path: text.contact.banner.action_path,
						text: text.contact.banner.action_button,
					}}
				/>

				<div className="restricted-container">
					<Alert label="Attention:">
						Due to the amount of requests we have been receiving to take in
						rescue animals, our volunteer network in both PA and TN is currently
						at capacity. We are working as hard as we can to make room for new
						arrivals. To help us increase this capacity and save more animals,
						please visit our <Link to="./donate">DONATE page</Link>. We
						apologize for the inconvenience and thank you for your patience.
					</Alert>
					<section className="split-when-large split-when-large--left">
						<div className="block-list">
							{text.contact.content_tiles.map((tile, index) => {
								return (
									<ContentTile
										key={index}
										image={contacts_image}
										items={tile.items}
										title={tile.title}
										paragraphs={tile.paragraphs}
									/>
								);
							})}
						</div>
					</section>

					<section className="social-media-tiles split-when-large split-when-large--right">
						<Form
							id={text.contact.banner.action_path}
							form_name="ContactForm"
							validatorTypes={ContactUsProperties}
							title={text.contact.form.title}
							fields={ContactUsFields}
							action_path={text.contact.form.action_path}
							action_function={postContactUsForm}
							action_button={text.contact.form.action_button}
						/>
					</section>
				</div>
			</section>
		);
	}
}
