import * as React from "react";
import { Link } from "react-router-dom";
import "./DividedList.scss";

export default class DividedList extends React.Component<any, any> {
	render() {
		return (
			<ul className="divided-list">
				{this.props.items.map((item, index) => {
					return <li key={index}>{item}</li>;
				})}
			</ul>
		);
	}
}
