import * as React from "react";
import "./TextDocument.scss";

export default class TextDocument extends React.Component<any, any> {
	render() {
		// *** UGLY HACK ***
		// Typescript whines about using the "type" attribute on <ol> tags, so
		// we need to inject the tags as an HTML string. It's quite stupid.
		// Supposedly React is fixing this in their types, but who knows.
		// https://stackoverflow.com/a/42629939/1316229
		return (
			<div
				className="text-container text-document"
				dangerouslySetInnerHTML={{ __html: this.props.html }}
			></div>
		);
	}
}
