import axios from "axios";
import { VolunteerInterface } from "../../../../schemas/VolunteerSchema";

export function postVolunteerForm(form_data: VolunteerInterface) {
	return axios({
		method: "post",
		url: "/api/volunteer",
		data: form_data,
	});
}
