import * as React from "react";
import { Link } from "react-router-dom";
import "./IconTile.scss";

export default class IconTile extends React.Component<any, any> {
	render() {
		return (
			<div className="icon-tile-container">
				<Link to={this.props.to} className="icon-tile">
					<img className={`icon-tile__icon icon`} src={this.props.icon} />
					<div className="chevron"></div>
					<div className="icon-tile__text">
						<div className="icon-tile__text__title">{this.props.title}</div>
						<div className="icon-tile__text__subtitle">
							{this.props.subtitle}
						</div>
					</div>
				</Link>
			</div>
		);
	}
}
