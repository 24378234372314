/*
THIS CODE IS COPIED FROM react-router-config:
https://github.com/ReactTraining/react-router/blob/master/packages/react-router-config/modules/renderRoutes.js

Transitions wouldn't work correctly with the current version on npm, so here we are...
*/

import * as React from "react";
import { Switch, Route } from "react-router-dom";

export const renderRoutes = (routes, extraProps = {}, switchProps = {}) =>
	routes ? (
		<Switch {...switchProps}>
			{routes.map((route, i) => (
				<Route
					key={route.key || i}
					path={route.path}
					exact={route.exact}
					strict={route.strict}
					render={(props) => {
						return (
							<route.component
								{...props}
								{...extraProps}
								{...route.props}
								route={route}
							/>
						);
					}}
				/>
			))}
		</Switch>
	) : null;
