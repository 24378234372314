import { SocialMediaPost } from "../models/SocialMediaPost";

import {
	INVALIDATE_SOCIAL_MEDIA_POSTS,
	RECEIVE_SOCIAL_MEDIA_POSTS,
} from "../constants";

class SocialMediaPostsReducerState {
	is_fetching: boolean;
	social_media_posts: SocialMediaPost[];
}

export const socialMediaPostReducer = (
	state: SocialMediaPostsReducerState = {
		is_fetching: false,
		social_media_posts: [],
	},
	action: any
) => {
	let new_state = Object.assign({}, state);
	switch (action.type) {
		case INVALIDATE_SOCIAL_MEDIA_POSTS:
			new_state.is_fetching = true;
			return new_state;

		case RECEIVE_SOCIAL_MEDIA_POSTS:
			new_state.is_fetching = false;
			new_state.social_media_posts = action.payload.social_media_posts;
			return new_state;

		default:
			return state;
	}
};
