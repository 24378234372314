import * as Joi from "joi";

export const ContactUsFields = {
	chapter_location: {
		label: "TASOW Chapter Location",
		placeholder: "Chapter Location",
		type: "radio",
		options: [
			{
				label: "Western Pennsylvania",
				description: "The Greater Pittsburgh Area",
				value: "PA",
			},
			{
				label: "Eastern Tennessee",
				description: "The Greater Chattanooga Area",
				value: "TN",
			},
		],
		required: true,
	},
	email: {
		label: "Email",
		placeholder: "steve@example.com",
		type: "email",
		required: true,
	},
	firstname: {
		label: "First Name",
		placeholder: "Steve",
		type: "text",
		required: true,
		validation: "",
	},
	lastname: {
		label: "Last Name",
		placeholder: "Irwin",
		type: "text",
		required: true,
		validation: "",
	},
	message: {
		label: "Your message",
		placeholder: "Speak your mind",
		type: "textarea",
		required: true,
	},
};

export interface ContactUsInterface {
	chapter_location: any;
	email: any;
	firstname: any;
	lastname: any;
	message: any;
}

export const ContactUsProperties: ContactUsInterface = {
	chapter_location: Joi.string().required(),
	email: Joi.string().required(),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	message: Joi.string().required(),
};

export const ContactUsSchema = Joi.object().keys(ContactUsProperties);
