import * as React from "react";
import { enableUniqueIds } from "react-html-id";

import "./RadioButton.scss";

export default class RadioButton extends React.Component<any, any> {
	private nextUniqueId: any;
	private lastUniqueId: any;

	constructor(props) {
		super(props);
		enableUniqueIds(this);
	}

	render() {
		return (
			<div className="radio-button" onClick={this.props.onClick}>
				<input
					id={this.nextUniqueId()}
					className="radio-button__input"
					type="radio"
					name={this.props.input_name}
					value={this.props.input_value}
					onChange={this.props.onChange}
					checked={this.props.checked}
				/>
				<label htmlFor={this.lastUniqueId()} className="button button--radio">
					<span>{this.props.label_text}</span>
				</label>
			</div>
		);
	}
}
