import * as React from 'react';
import { connect } from 'react-redux';

export default function withRedux(WrappedComponent, redux_functions){
	let ConnectedComponent;
	if( redux_functions ) {
		ConnectedComponent = connect( redux_functions.mapStateToProps, redux_functions.mapDispatchToProps )( WrappedComponent );
	} else {
		ConnectedComponent = WrappedComponent;
	}
	return class FetchedData extends React.Component<any, any> {
		render() {
			// ... and renders the wrapped component with the fresh data!
			// Notice that we pass through any additional props
			return <ConnectedComponent {...this.props} />;
		}
	}
}
