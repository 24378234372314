import * as React from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/Banner/Banner";
import IconTile from "../../components/IconTile/IconTile";
import ContentTile from "../../components/ContentTile/ContentTile";
import SocialMediaTile from "../../components/SocialMediaTile/SocialMediaTile";
import Alert from "../../components/Alert/Alert";

import text from "../../locales/en-us";

import "./Home.scss";
export { default as HomeRedux } from "./Home.redux";

let banner_images = {
	large: require("./images/banner-1200x450.jpg"),
	medium: require("./images/banner-960x650.jpg"),
	small: require("./images/banner-475x292.jpg"),
};

export default class Home extends React.Component<any, any> {
	// // This is broken
	// componentWillMount() {
	// 	this.props.fetchSocialMediaPosts();
	// }

	render() {
		var social_media_tiles;
		let { social_media_posts = {} } = this.props;

		if (social_media_posts.social_media_posts) {
			social_media_tiles = (
				<section className="social-media-tiles split-when-large split-when-large--right">
					{social_media_posts.social_media_posts.map((post, index) => {
						return <SocialMediaTile key={index} data={post} />;
					})}
				</section>
			);
		}

		return (
			<section className="home-page">
				<Banner
					images={banner_images}
					text={text.home.banner.chattanooga}
					action_button={{
						path: text.home.banner.action_path,
						text: text.home.banner.action_button,
					}}
					no_margin={true}
				/>

				<section className="icon-tile-list bottom-spacing">
					<div className="icon-tiles-wrapper">
						{text.home.tile_buttons.map((button, index) => {
							return (
								<IconTile
									key={index}
									to={button.route}
									icon={button.icon}
									title={button.title}
									subtitle={button.description}
								/>
							);
						})}
					</div>
				</section>

				<div className="restricted-container">
					<section>
						<Alert label="Attention:">
							Due to the amount of requests we have been receiving to take in
							rescue animals, our volunteer network in both PA and TN is
							currently at capacity. We are working as hard as we can to make
							room for new arrivals. To help us increase this capacity and save
							more animals, please visit our{" "}
							<Link to="./donate">DONATE page</Link>. We apologize for the
							inconvenience and thank you for your patience.
						</Alert>
						<div className="block-list row-when-large">
							{text.home.content_tiles.map((tile, index) => {
								return (
									<ContentTile
										key={index}
										to={tile.route}
										items={tile.items}
										image={tile.image}
										title={tile.title}
										paragraphs={tile.paragraphs}
										action_button={text.global.learn_more}
									/>
								);
							})}
						</div>
					</section>

					{social_media_tiles}
				</div>
			</section>
		);
	}
}
