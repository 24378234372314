import * as React from "react";
import text from "../../locales/en-us";

import ContentTile from "../../components/ContentTile/ContentTile";

import "./DonationComplete.scss";

let paragraphs = [
	<span>
		Your transaction has been completed, and a receipt for your purchase has
		been emailed to you. You may log into your account at{" "}
		<a href="https://www.paypal.com" className="text-link">
			www.paypal.com
		</a>{" "}
		to view details of this transaction.
	</span>,
	<span>
		If you signed up for monthly donations, you can also manage these
		transactions through your{" "}
		<a
			href="https://www.paypal.com/cgi-bin/customerprofileweb?cmd=_manage-paylist"
			className="text-link"
		>
			paypal account
		</a>
		.
	</span>,
];

export default class DonationComplete extends React.Component<any, any> {
	render() {
		return (
			<section className="donation-complete-page">
				<div className="restricted-container">
					<div className="block-list">
						<ContentTile
							full_width={true}
							title={text.donation_complete.title}
							paragraphs={paragraphs}
						/>
					</div>
				</div>
			</section>
		);
	}
}
