// Google Analytics global variable
declare var ga: any;

import * as React from "react";
import MetaData from "../../components/MetaData/MetaData";

import "./asPage.scss";

var scrollToElement;
var previous_location;

// Adds some additional functionality useful for the top level page components
export default function asPage(WrappedComponent) {
	return class PageComponent extends React.Component<any, any> {
		componentDidMount() {
			// This needs to be required here so that it is not loaded on the server-side
			scrollToElement = require("scroll-to-element");
			this.jumpToHash();

			// Handle pageview analytics
			if (previous_location !== this.props.location.pathname) {
				ga("set", "page", this.props.location.pathname);
				ga("send", "pageview");
			}

			previous_location = this.props.location.pathname;
		}

		jumpToHash = () => {
			let hash = this.props.history.location.hash;
			if (hash) {
				scrollToElement(hash, { offset: -25, duration: 700 });
			}
		};

		render() {
			var { meta, route, ...props } = this.props;
			return (
				<div>
					{route.meta && (
						<MetaData {...route.meta} path={this.props.location.pathname} />
					)}
					<WrappedComponent {...props} />
				</div>
			);
		}
	};
}
