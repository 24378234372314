import * as React from "react";
import { Provider } from "react-redux";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
//import { renderRoutes } from 'react-router-config';

import { renderRoutes } from "./util/renderRoutes";

import AppContainer from "./AppContainer";
import routes from "./routes";

import store from "./store/";

import "./styles/main.scss";

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
	</Provider>,
	document.getElementById("container")
);
