import * as React from "react";
import MetaTags from "react-meta-tags";
const twitter_image = require("../../pages/Home/images/meta_twitter.png");
const facebook_image = require("../../pages/Home/images/meta_facebook.png");

var scrollToElement;

export default class MetaData extends React.Component<any, any> {
	render() {
		let { title, description, path } = this.props;
		return (
			<MetaTags>
				<title>{title}</title>
				<meta id="meta1" name="description" content={description} />

				<meta id="meta2" name="twitter:card" content="summary" />
				<meta id="meta3" name="twitter:site" content="@TASOW" />
				<meta id="meta4" name="twitter:title" content={title} />
				<meta id="meta5" name="twitter:description" content={description} />
				<meta id="meta6" name="twitter:creator" content="@TASOW" />
				<meta
					id="meta7"
					name="twitter:image"
					content={`https://www.tasow.org/${twitter_image}`}
				/>

				<meta id="meta8" property="og:title" content={title} />
				<meta id="meta9" property="og:type" content="website" />
				<meta
					id="meta10"
					property="og:url"
					content={`https://www.tasow.org${path}`}
				/>
				<meta
					id="meta11"
					property="og:image"
					content={`https://www.tasow.org/${facebook_image}`}
				/>
				<meta id="meta12" property="og:description" content={description} />
				<meta
					id="meta13"
					property="og:site_name"
					content="TASOW - The Awesome Spirit Of Wildlife"
				/>
				<meta id="meta14" property="fb:app_id" content="1494504887518271" />
			</MetaTags>
		);
	}
}
